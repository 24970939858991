<template>
    <div class='page'>
        <div class="body1">
            <img class="product1" src="@/assets/images/careDetail1.png" alt="">
            <div class="info wow fadeInDown">
                <!-- 导航 -->
                <div class="nav dis_flex items_center">
                    <div @click="$utils.backPage()">{{ $t('careDetail.value1') }}</div>
                    <div>{{ $t('careDetail.value2') }}</div>
                </div>
                <div class="title">{{ $t('careDetail.value2') }}</div>
            </div>
        </div>
        <div class="body2">
            <img src="@/assets/images/careDetail2.png" alt="">
            <div class="info">
                <div class="title wow fadeInDown">{{  $i18n.locale == 'zh' ? careInfo.title : careInfo.titleEn  }}</div>
                <div class="time wow fadeInDown">{{ $moment(careInfo.createTime).format('YYYY-MM-DD') }}</div>
            </div>
        </div>
        <div class="body3">
            <div v-html="$i18n.locale == 'zh' ? careInfo.content : careInfo.contentEn"></div>
        </div>
    </div>
</template>

<script>
import { careDetail } from '@/api/index'
export default {
    data() {
        return {
           careInfo:{}
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live:false
            }).init()
        })
        this.getCareDetail()
    },
    methods: {
        async getCareDetail() {
            try {
                const res = await careDetail({ id: this.$route.query.id })
                this.careInfo = res.data
                console.log(res);
            } catch (err) {
                console.log(err);
            }
        },
    },
}
</script>
<style scoped lang='scss'>
    .body1{
        position: relative;
        width: 100%;
        height: 520px;
        padding-top: 250px;
        box-sizing: border-box;
        .product1{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        .info{
            color: #FFFFFF;
            position: relative;
            padding-left: 50px;
            box-sizing: border-box;
            .nav{
                div{
                    padding: 0 15px;
                    border-right: 1px solid #E9E9E9;
                    color: #fff;
                    font-size: 16px;
                    cursor: pointer;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        border-right: none;
                        font-weight: bold;
                    }
                }
            }
            .title{
                font-size: 60px;
                font-weight: bold;
                margin-top: 65px;

            }
        }
    }
    .body2{
        width: 100%;
        height: 290px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
        .info{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 110px);
            padding-left: 110px;
            .title{
                font-weight: bold;
                font-size: 42px;
                margin-bottom: 25px;
                width: 70%;
                line-height: 1.4;
            }
            .time{
                color: #9BBAC4;
                font-size: 20px;
                font-weight: bold;
                width: fit-content;
            }
        }
    }
    .body3{
        padding: 0 125px 85px;
        box-sizing: border-box;
        img{
            width: 100%;
        }
    }
</style>
